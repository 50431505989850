<template>
    <v-dialog
        :value="showAttentionMobileVersionDialog"
        max-width="570"
        @input="$emit('input')"
    >
        <div class="coming_soon_dialog style_dialog">
            <h2 class="header">
                <div class="border_head">
                    <span class="big-letter">Attention</span>
                </div>
            </h2>
            <div class="cnt mobile_attention">
                This project is in <button type="button" @click="redirectToFAQ">alpha development stage.</button>
                Comments and other aspects of the site may be reset before the official launch.
            </div>
            <div class="wrap_actions center">
                <button type="button" class="is-nope" @click="ok()" v-ripple>I understand</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "attentionMobileVersion",
    props: ['value'],
    computed: {
        showAttentionMobileVersionDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ok() {
            this.showAttentionMobileVersionDialog = false;
            localStorage.setItem('psytranc_understand', 'isSet');
        },
        redirectToFAQ() {
            this.showAttentionMobileVersionDialog = false;
            this.$router.push({name: 'faq'});
        }
    }
}
</script>

<style lang="scss">
.mobile_attention {
    flex-direction: column;
    > a, button {
        color: #20C4F5;
    }
}
</style>
